
import { visitorService } from "@/service";
import { computed, defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const detail = reactive<any>({});
    const myVisitorDetail = async () => {
      const { data } = await visitorService.myVisitorDetail({
        detail_code: route.params.id
      });
      Object.assign(detail, data.data);
    };
    myVisitorDetail();

    const imgSrc = computed(() => {
      if (detail.state == "1") {
        return require("@/assets/images/visitor/3.png");
      }
      if (detail.state_name == "已过期") {
        return require("@/assets/images/visitor/1.png");
      }
      if (detail.state == "3") {
        return require("@/assets/images/visitor/2.png");
      }
      return detail.qrcode;
    });

    return {
      imgSrc,
      detail
    };
  }
});
